import React from "react";
import { Card } from "react-bootstrap";
import LoginForm from "../../components/auth/LoginForm";
const Login = () => {
  return (
    <div className="auth-container">
      <Card className="auth--wrapper">
        <Card.Body>
          <div className="logo-container">
            <img src="/logo.png" className="logo" alt="Logo Keziah Paris" />
          </div>
          <LoginForm />
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
