/*
Author : Mateo Mirindi
Company : Numerik
Fichier : src/routes/api.js
Created On : 12 May 2022 16:39:03
*/

export const host =
  process.env.NODE_ENV !== "production"
    ? "http://127.0.0.1:8000"
    : "https://api.keziah-paris.com";
export const base_url = host + "/api/admin";

//auth
export const CSRF_ROUTE = host + "/sanctum/csrf-cookie";
export const API_LOGIN_ROUTE = host + "/admin/login";
export const API_LOGOUT_ROUTE = host + "/admin/logout";

//dashboard
export const DASHBOARD = base_url + "/dashboard";
export const API_DASHBOARD_FETCH_STATS_OVERVIEW = DASHBOARD + "/stats-overview";
export const API_DASHBOARD_FETCH_SEARCH_TERMS = DASHBOARD + "/search-terms";

//listings
export const LISTINGS = base_url + "/listings";
export const CREATE_LISTING = LISTINGS + "/create";
export const STORE_LISTING = LISTINGS + "/store";
export const SHOW_LISTING = LISTINGS + "/show/:id";
export const EDIT_LISTING = LISTINGS + "/edit/:id";
export const UPDATE_LISTING = LISTINGS + "/update/:id";
export const LISTING_DELETE = LISTINGS + "/delete/:id";
//* LISTING VARIATIONS TYPES
export const LISTINGS_VARIATIONS_TYPES = LISTINGS + "/variations-types";
export const STORE_LISTINGS_VARIATIONS_TYPE =
  LISTINGS_VARIATIONS_TYPES + "/store";
export const SHOW_LISTINGS_VARIATIONS_TYPE =
  LISTINGS_VARIATIONS_TYPES + "/show/:id";
export const STORE_LISTINGS_VARIATIONS_TYPE_VARIANT =
  LISTINGS_VARIATIONS_TYPES + "/variants/store";
export const DELETE_LISTINGS_VARIATIONS_TYPE_VARIANT =
  LISTINGS_VARIATIONS_TYPES + "/variants/destroy/:id";
//* LISTING CATEGORIES
export const API_LISTING_CATEGORIES_ROUTE = LISTINGS + "/categories";
export const API_STORE_CATEGORY_ROUTE = API_LISTING_CATEGORIES_ROUTE + "/store";
export const API_SHOW_CATEGORY_ROUTE =
  API_LISTING_CATEGORIES_ROUTE + "/show/:id";
export const API_CATEGOEY_TOGGLE_LISTING_ROUTE =
  API_LISTING_CATEGORIES_ROUTE + "/toggle-listing/:id";
export const API_CATEGORY_TOGGLE_TOP_CATEGORIES_ROUTE =
  API_LISTING_CATEGORIES_ROUTE + "/toggle-top-categories/:id";
export const API_SEARCH_CATEGORY =
  API_LISTING_CATEGORIES_ROUTE + "/search?query=:query";
//brands
export const API_BRANDS = LISTINGS + "/brands";
export const API_BRANDS_STORE = LISTINGS + "/brands/store";
export const API_SEARCH_BRAND = API_BRANDS + "/search?query=:query";

//* listings collections
export const API_LISTING_COLLECTIONS_ROUTE = LISTINGS + "/collections";
export const API_SHOW_COLLECTION_ROUTE = LISTINGS + "/collections/show/:id";
export const API_STORE_COLLECTION_ROUTE =
  API_LISTING_COLLECTIONS_ROUTE + "/store";
export const API_COLLECTION_TOGGLE_TOP_COLLECTIONS_ROUTE =
  API_LISTING_COLLECTIONS_ROUTE + "/toggle-top-collections/:id";
export const API_COLLECTION_TOGGLE_LISTING_ROUTE =
  API_LISTING_COLLECTIONS_ROUTE + "/toggle-listing/:id";

//orders
export const API_ORDERS_ROUTE = base_url + "/orders";
export const API_ORDERS_OVERVIEW_ROUTE = API_ORDERS_ROUTE + "/overview";
export const API_FETCH_ORDER_DETAILS = API_ORDERS_ROUTE + "/show/:id";
export const API_PAY_OFFLINE_ORDER_ORDER_ROUTE =
  base_url + "/orders/pay-offline/:id";
export const API_CANCEL_ORDER_ROUTE = base_url + "/orders/cancel/:id";
export const API_ORDER_MARK_AS_TREATED_ROUTE =
  base_url + "/orders/mark-as-treated/:id";

//finances
const FINANCES = base_url + "/finances";
//* CURRENCIES
export const CURRENCIES = FINANCES + "/currencies";
export const STORE_CURRENCY = CURRENCIES + "/store";
export const SET_MAIN_CURRENCY = CURRENCIES + "/set-main/:id";
export const TOGGLE_CURRENCY_STATUS = CURRENCIES + "/toggle-status/:id";
export const CURRENCY_SHOW = CURRENCIES + "/show/:id";
export const EDIT_CURRENCY_API = CURRENCIES + "/edit/:id";
export const UPDATE_CURRENCY = CURRENCIES + "/update/:id";
//EXCHANGE RATES
const EXCHANGE_RATE = FINANCES + "/exchange-rate";
export const CREATE_EXCHANGE_RATE = EXCHANGE_RATE + "/create";
export const STORE_EXCHANGE_RATE = EXCHANGE_RATE + "/store";
export const DELETE_EXCHANGE_RATE = EXCHANGE_RATE + "/delete/:id";
//* PAYMENT METHODS
export const PAYMENT_METHODS = FINANCES + "/payment-methods";
export const STORE_PAYMENT_METHOD = PAYMENT_METHODS + "/store";

//settings
const SETTINGS = base_url + "/settings";
//* Countries
export const API_COUNTRIES = SETTINGS + "/countries";
export const API_STORE_COUNTRY = API_COUNTRIES + "/store";
export const API_SHOW_COUNTRY = API_COUNTRIES + "/show/:id";
export const API_STORE_PROVINCE = API_COUNTRIES + "/provinces/store";
export const API_STORE_CITY = API_COUNTRIES + "/provinces/cities/store";
export const API_STORE_TOWN = API_COUNTRIES + "/provinces/cities/towns/store";
export const API_STORE_DISTRICT =
  API_COUNTRIES + "/provinces/cities/towns/districts/store";

//* shipping profile
export const API_SHIPPING_PROFILES = SETTINGS + "/shipping-profile";
export const API_STORE_SHIPPING_PROFILE = SETTINGS + "/shipping-profile/store";
